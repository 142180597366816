import React from 'react';
import styled, { withTheme, css } from 'styled-components';
import useMedia from 'react-use/lib/useMedia';
import {Link as GatsbyLink} from "gatsby";
import { media } from '../theme';


// Components
import { Space, Background, Card } from '../components';

// Styles
import { HeadLegal, TextLegal, ALegal, Content, Section, OL, OI } from '../styles';

const TermsConditions = ({ theme }) => {
	const isPhone = useMedia(`(max-width: ${theme.sizes.phone}px)`);
	return (
		<Content>
			<Section id="termsConditions">
				<Background
					color={theme.secondaryColor}
					padding={{phone: '19px 19px 55px 19px', def: '79px 60px 100px 18px'}}
				>
					<Card
						spacing={spacing}
						margin={{phone: '0',  def: '0 0 0 -40px'}}
					>
						<HeadLegal>Online (including App) Terms and Conditions</HeadLegal>
						<Space height="20" />
						<OL bold>
							<OI>
								These terms
								<OL>
									<OI>
										<InlineText bold>What these terms cover.</InlineText> These are the terms and
										conditions (
										<InlineText bold>Agreement</InlineText>) on which we supply our Service (defined
										under clause 5.1 below) to you, whether this is services or digital content.
									</OI>
									<OI>
										<InlineText bold>Defined terms.</InlineText> Capitalized words (which are not
										proper nouns) have a specific definition. These words are defined in the terms.
										You can search the terms to see where the words are defined.
									</OI>
									<OI>
										<InlineText bold>Why you should read them.</InlineText> Please read these terms
										carefully. These terms tell you who we are, how we will provide the Service to
										you, how you and we may change or end the Agreement, what to do if there is a
										problem and other important information.
									</OI>
								</OL>
							</OI>
							<OI>
								Information about us and how to contact us
								<OL>
									<OI>
										<InlineText bold>Who we are.</InlineText> We are AVSecure LLC (<InlineText bold>AVSecure, we, us</InlineText>)
										a company incorporated and registered in the state of Delaware, in the United
										States of America. Our trading office is at 1097 Jupiter Park Lane, Suite 3,
										Jupiter, Florida, USA, 33458.
									</OI>
									<OI>
										<InlineText bold>What we do.</InlineText> Either through our website (
										<LinkLegal
											to="https://www.agepass.com"
										>
											www.agepass.com
										</LinkLegal>
										<InlineText> or </InlineText>
										<ALegal
											href="https://www.agepass.net"
											target="_blank"
											rel="noopener noreferrer"
										>
											www.agepass.net
										</ALegal>) or through our mobile application (<InlineText bold>App</InlineText>),
										we verify a customer’s age and, if aged 18 or over, provide an access key with
										which you can set up an age pass (<InlineText bold>AgePass</InlineText>) to
										access certain restricted websites or other content online.
									</OI>
									<TextLegal>
										The AgePass is a digital pass which attests that a user has verified their age
										in a way that is acceptable to the British Board of Film Classification (the age
										verification regulator) and is provided by AVSecure LLC. Our Service is provided
										so that the user may create an AgePass.
									</TextLegal>
									<TextLegal>
										<InlineText bold>IMPORTANT:</InlineText> An AgePass does not guarantee a user
										access to any website with restricted online content. An AgePass will only work
										to give access to content which is restricted by an AVSecure product. A website
										with restricted content may choose to use an age verification wall/blocker or
										other content blocker other than an AVSecure product, even where they have used
										an AVSecure in the past.
									</TextLegal>
									<OI>
										<InlineText bold>This Agreement.</InlineText> This Agreement sets out the terms
										and conditions under which we supply age verification services and AgePass
										online through our website or App, whether for a fee or free of charge.
									</OI>
									<OI>
										<InlineText bold>How to contact us.</InlineText> As a feature of our Service, we
										do not request or receive any directly identifiable information about you at any
										point. We only know that an AgePass access key holder is eighteen (18) years or
										older. You can read about this in our privacy policy (see clause 12 of this
										agreement). If you wish to contact us, you can do so by using this form{' '}
										<LinkLegal
											to="/#contact"
										>
											www.agepass.com/#contact
										</LinkLegal>
										<InlineText> on our website. </InlineText>
										<InlineText bold>
											Please note that by contacting us you are choosing to lose your anonymity as
											a user of our Service.
										</InlineText>{' '}
										If you wish to discuss your order, we will not be able to do so unless you have
										your AgePass access key.
									</OI>
									<OI>
										<InlineText bold>How we may contact you. </InlineText>Because we do not request
										any directly identifiable information from you at any point in providing our
										Service, we can only and will only contact you directly if you have provided us
										with your contact details as set out in clause 2.4. If we have to contact you
										directly, we will do so by responding to you using the details you have provided
										to us when you contact us.
									</OI>
								</OL>
							</OI>
							<TextLegal>
								Any other announcements for our customers will be set out on our website.
							</TextLegal>
							<OI>
								Our Agreement with you
								<OL>
									<OI>
										<InlineText bold>How you may order our Service. </InlineText>You may order our
										Service by using our website or using our App, selecting your age verification
										method, providing any required details and submitting your order.
									</OI>
									<OI>
										<InlineText bold>How we will accept your order. </InlineText>We will accept your
										AgePass order when you choose to use one of our age verification methods and you
										have been successfully age verified. At this point we will confirm in your
										browser or in-App that your order has been accepted and a legal contract as set
										out in this Agreement will come into existence between you and us. Provided that
										our Service confirms that you are eighteen (18) years old or over, your AgePass
										access key will be displayed to you.
									</OI>
									<OI>
										<InlineText bold>How this Agreement comes to an end. </InlineText>This Agreement
										ends when the access key for your AgePass is delivered; or, earlier as expressly
										described in this Agreement.
									</OI>
									<OI>
										<InlineText bold>If we cannot accept your order. </InlineText>If we are unable
										to fulfil your order, we will inform you of this in your browser session (or
										in-App) when you attempt to purchase or set up (depending on the age
										verification method you choose) our Service online. In certain circumstances, it
										may not be possible to inform you in your browser session (or in-App) that your
										order cannot be fulfilled. In the case that we cannot fulfil your order and a
										fee is payable for the Service, we will not charge you. We may render any
										AgePass access key you have received unusable and this Agreement will come to an
										end. Your order might not be fulfilled, for example, because of unexpected
										limits on our resources which we could not reasonably plan for, because we or
										our third party payment processor cannot process your payment; or, because we
										have identified an error in the price or description of the Service.
									</OI>
									<OI>
										<InlineText bold>
											We only provide the Service in the UK at present.{' '}
										</InlineText>The AgePass is currently only available in the UK. If you order or
										use our Service while outside the UK, you are responsible for ensuring that you
										are in compliance with all local laws and regulations.
									</OI>
									<OI>
										<InlineText bold>
											Your use of the Service in-App may also be controlled by the terms of the
											Appstore from which you downloaded our App.{' '}
										</InlineText>The ways in which you can use the App may also be controlled by the
										relevant Appstore’s rules and policies and the relevant Appstore’s rules and
										policies will apply instead of or in addition to the terms of this Agreement
										where there are differences between the two.
									</OI>
								</OL>
							</OI>
							<OI>
								Our rights to make changes
								<OL>
									<OI>
										<InlineText bold>Minor changes to the Service. </InlineText>
										We may change the Service:
										<OL>
											<OI>
												to reflect changes in relevant laws and regulatory requirements; and
											</OI>
											<OI>to implement minor technical adjustments and improvements.</OI>
										</OL>
									</OI>
									<OI>
										<InlineText bold>
											More significant changes to the Service and this Agreement.{' '}
										</InlineText>
										In addition, we may make changes to this Agreement or the Service, but if we do
										so we will notify you via our website and/or App.
									</OI>
								</OL>
							</OI>
							<OI>
								Providing the Service
								<OL>
									<OI>
										<InlineText bold>The Service. </InlineText>
										Our Service is comprised of two distinct parts as described below. Please see
										our FAQs{' '}
										<LinkLegal
											to="/faqs"
										>
											https://www.agepass.com/faqs
										</LinkLegal>
										<InlineText> for further information.</InlineText>
										<OL>
											<OI>
												<InlineText bold>Our age verification service. </InlineText>We will
												begin the age verification service after you make your order. Age
												verification is completed via our third party suppliers, as applicable
												based on your chosen age verification method (see below). Except when
												verifying using an Age Verification Card, you will be redirected to the
												relevant third party supplier according to the age verification method
												which you select. The age verification service should be completed
												shortly afterwards.
												<TextLegal textIndent="-1.7em">
													The methods which you can use to verify your age via AgePass
													(whether via the website or in-App) are:
												</TextLegal>
												<OL>
													<OI>
														<InlineText bold>Age Verification Card: </InlineText>This is
														available at retail stores throughout the UK to those aged
														eighteen (18) years or older. After selecting this option, enter
														the code on your Age Verification Card to be issued with an
														AgePass access key.
													</OI>
													<OI>
														<InlineText bold>UK Paysafecard: </InlineText>We charge a fee
														for using this age verification method. After selecting this
														option, enter your UK Paysafecard number. Once your age has been
														verified and the fee payable debited, you will be issued with an
														AgePass access key.
													</OI>
												</OL>
											</OI>
											Currently, the only method for which we, AVSecure, charge a fee is your UK
											Paysafecard. The other methods do not involve AVSecure charging you a fee.
											<OI>
												<InlineText bold>Our AgePass. </InlineText>We will provide the access
												key for AgePass to you as soon as we have successfully completed our age
												verification service. Upon receipt of the access key, you will be asked
												to create a pin which is connected to your access key.
											</OI>
											<TextLegal textIndent clear bold>
												YOU MUST MAKE A SEPARATE NOTE OF YOUR ACCESS KEY WHEN DISPLAYED TO YOU
												FOLLOWING YOUR ORDER (E.G. IN HARDCOPY, BY TAKING A PHOTOGRAPH, OR
												SAVING IN A WORD DOCUMENT). IF YOU CLOSE THE BROWSER WINDOW OR APP ON
												WHICH YOUR ACCESS KEY IS DISPLAYED WITHOUT MAKING A NOTE OF IT, YOU WILL
												NOT BE ABLE TO USE YOUR AGEPASS TO ACCESS RESTRICTED CONTENT ON WEBSITES
												WHICH USE AN AVSECURE PRODUCT.
											</TextLegal>
											<TextLegal textIndent clear bold>
												IF YOUR CHOSEN AGE VERIFICATION METHOD INVOLVED PAYMENT OF A FEE AND YOU
												DO NOT MAKE A NOTE OF YOUR ACCESS KEY WHEN DISPLAYED, YOU WILL NOT BE
												PROVIDED WITH A REFUND AS WE HAVE FULLY CARRIED OUT THE SERVICE TO YOU.
											</TextLegal>
										</OL>
									</OI>
									We will provide and perform the Service to you as soon as you place your order
									either via our website or in-App, as agreed and requested by you pursuant to this
									Agreement.
									<OI>
										<InlineText bold>
											You agree to receive the Service immediately and cannot change your mind.{' '}
										</InlineText>By accepting this Agreement, you consent to the immediate
										performance of the Agreement and acknowledge that you will lose your right of
										withdrawal from the Agreement once the Service has been completed and your
										AgePass access key displayed to you. You do not have a right to change your mind
										in respect of AgePass once you have paid the fee (if applicable in respect of
										your chosen age verification method) and/or we have displayed to you your
										AgePass access key.
									</OI>
									<OI>
										<InlineText bold>
											We are not responsible for delays outside our control.{' '}
										</InlineText>If our supply of the Service is affected by an event outside our
										control then we will inform you as soon as possible and take steps to minimise
										the effect of the delay. We will not be liable for delays caused by the event
										but if you paid for but did not receive the Service, we will endeavour to
										provide you with the Service again without charge.
									</OI>
									<OI>
										<InlineText bold>
											What will happen if you do not give required information to us.{' '}
										</InlineText>As part of the supply of the Service, you will be connected with
										our third party suppliers who need information from you to verify your age as
										eighteen (18) years or older. This information differs depending on the age
										verification method you have chosen. If you do not provide this information, or
										you provide incomplete or incorrect information, we will (as relevant) refuse to
										provide you with the AgePass and/or end the Agreement and cancel the operation
										of the AgePass.
									</OI>
									<OI>
										<InlineText bold>
											Reasons we may suspend the supply of the Service to you.{' '}
										</InlineText>We may have to suspend the Service to:
										<OL>
											<OI>deal with technical problems or make minor technical changes;</OI>
											<OI>
												update the Service to reflect changes in relevant laws and regulatory
												requirements;
											</OI>
											<OI>
												make changes to the Service as notified by us to you via the website
												and/or App.
											</OI>
										</OL>
									</OI>
									From time to time we may automatically update the App and change the Service to
									improve performance, enhance functionality, reflect changes to the operating system
									or address security issues. Alternatively, we may ask you to update the App for
									these reasons. If you choose not to install such updates or if you opt out of
									automatic updates you may not be able to continue using the App and/or the Service.
									<OI>
										<InlineText bold>We may cancel the AgePass if you do not pay. </InlineText>Where
										your chosen age verification method requires payment of a fee, if you do not
										pay, we may cancel the operation of the AgePass without notice to you.
									</OI>
									<OI>
										<InlineText bold>
											You must not provide details of your AgePass to others or allow others to
											use your AgePass.{' '}
										</InlineText>AgePass may only be used by the individual who verified their age
										as eighteen (18) years or older. You must not share your AgePass access key or
										pin with any other person. Where we suspect an AgePass is being used by more
										than one person, we may end the Agreement in accordance with clause 8 and/or
										subject that AgePass to re-verification checks.
									</OI>
									<OI>
										<InlineText bold>
											AgePass is provided on an “as is” basis only and we exclude all warranties
											to the extent permitted by law.{' '}
										</InlineText>Other than as expressly set out in this Agreement, we do not make
										any specific promises about AgePass. For example, we do not make any commitments
										about AgePass, the specific functions of AgePass, or its reliability,
										availability, or ability to meet your needs. We provide AgePass “as is”. To the
										extent permitted by law, we exclude all warranties.
									</OI>
									<OI>
										<InlineText bold>Your licence to use the Service. </InlineText>AVSecure gives
										you a personal, worldwide, royalty-free, non-assignable and non-exclusive
										license to use the software provided to you by AVSecure as part of the Service.
										This license is for the sole purpose of enabling you to use and enjoy the
										benefit of AgePass as provided by AVSecure, in the manner permitted by this
										Agreement. User credentials may be used to enable access on multiple devices,
										within our discretion. Unless otherwise terminated by you due to your deletion
										of the App and/or your AgePass, or otherwise terminated by us under this
										Agreement, this license shall remain in effect for as long as we provide the
										Service to you and you use the Service. You may not copy, modify, distribute,
										sell, or lease any part of AgePass and/or the Service or included software, nor
										may you reverse engineer or attempt to extract the source code of that software.
										You must comply with all applicable technology control or export laws and
										regulations that apply to the technology used or supported by AgePass and/or the
										Service.
									</OI>
									<OI>
										<InlineText bold>
											You cannot use any AgePass intellectual property.{' '}
										</InlineText>All intellectual property rights in AgePass, the Service, and/or
										the App throughout the world belong to us (or our licensors) and the rights in
										AgePass, the Service and/or the App are licensed (not sold) to you. You have no
										intellectual property rights in, or to, AgePass, the Service and/or the App
										other than the right to use it in accordance with these terms.
									</OI>
									<OI>
										<InlineText bold>
											Restrictions on your use of the App and the Service.{' '}
										</InlineText>You must not:
										<OL>
											<OI>
												use the App or any Service in any unlawful manner, for any unlawful
												purpose, or in any manner inconsistent with these terms, or act
												fraudulently or maliciously, for example, by hacking into or inserting
												malicious code, such as viruses, or harmful data, into the App, the
												Service or any operating system;
											</OI>
											<OI>
												infringe our intellectual property rights or those of any third party in
												relation to your use of the App or the Service;
											</OI>
											<OI>
												transmit any material that is defamatory, offensive or otherwise
												objectionable in relation to your use of the App or the Service;
											</OI>
											<OI>
												use the App or the Service in a way that could damage, disable,
												overburden, impair or compromise our systems or security or interfere
												with other users; and
											</OI>
											<OI>
												collect or harvest any information or data from the Service or our
												systems or attempt to decipher any transmissions to or from the servers
												running the Service.
											</OI>
										</OL>
									</OI>
								</OL>
							</OI>
							<OI>Your rights to end the Agreement</OI>
							<TextLegal>
								<InlineText bold>You can always end your Agreement with us. </InlineText>You may end
								your use of the Service, and delete any App associated with AgePass at any time should
								you no longer wish to use the Service. We will deem you taking such action as an express
								termination of this Agreement and any license granted to you under this Agreement. Your
								rights when you end the Agreement will depend on whether there is anything wrong with
								your AgePass. If your AgePass is faulty or misdescribed you may have a legal right to
								end the Agreement (or to a replacement AgePass). Where necessary, you can contact us as
								set out in clause 2.4.
							</TextLegal>
							<OI>
								How and when we will refund you
								<OL>
									<OI>
										We do not offer refunds where your chosen age verification method does not
										involve payment of a fee.
									</OI>
									<OI>
										Where your chosen age verification method involves payment of a fee, we do not
										offer refunds once the Service has been completed and your AgePass access key
										has been displayed to you.
									</OI>
									<OI>
										If a refund is due under the terms of this Agreement, we will refund you the
										price you paid for the Service (if any, in accordance with your chosen age
										verification method), by the method you used for payment. We will make any
										refunds due to you as soon as possible and in any event within 14 days of us
										agreeing you are entitled to a refund.
									</OI>
								</OL>
							</OI>
							<OI>
								Our rights to end the Agreement
								<OL>
									<OI>
										<InlineText bold>We may end the Agreement if you breach it. </InlineText>We may
										end the Agreement at any time if:
										<OL>
											<OI>you do not make any payment to us when it is due;</OI>
											<OI>
												you do not, during the order process, provide us with information that
												is necessary for us to provide the Service, for example, sufficient
												identification to verify your age as eighteen (18) years or older;
											</OI>
											<OI>
												we suspect an AgePass is being used fraudulently or in breach of
												applicable law or regulations or we are required to make changes to
												AgePass and/or the Services by any court or regulatory authority;
											</OI>
											<OI>if you are in material breach of the terms of this Agreement; or</OI>
											<OI>
												we decide to cease operations, discontinue the Service or parts of the
												Service, or change or revise any aspect of the Service.
											</OI>
										</OL>
									</OI>
									<OI>
										<InlineText bold>
											We may refuse to provide you with an AgePass, cancel any AgePass provided to
											you, or require you to re-verify your age.{' '}
										</InlineText>Where one of the circumstances in clause 8.1 applies, we may refuse
										to provide you with an AgePass, cancel any AgePass or the Service provided to
										you, or require you to re-verify your age through an available age verification
										method on or through the Platforms (as applicable).
									</OI>
								</OL>
							</OI>
							<OI>
								If there is a problem with the Service
								<OL>
									<OI>
										<InlineText bold>Visit our list of FAQs. </InlineText>We have an extensive list
										of FAQs available here{' '}
										<LinkLegal
											to="/faqs"
										>
											www.agepass.com
										</LinkLegal>{' '}
										<InlineText> which should be able to resolve your query.</InlineText>
									</OI>
									If you need to contact us, you can do so as set out in clause 2.4.
									<OI>
										<InlineText bold>Summary of your legal rights. </InlineText>See the box below
										for a summary of your key legal rights in relation to the Service. Nothing in
										these terms will affect your legal rights.
										<TextLegal bold>Summary of your key legal rights</TextLegal>
										<TextLegal>
											This is a summary of your key legal rights. These are subject to certain
											exceptions. For detailed information please visit the Citizens Advice
											website{' '}
											<ALegal
												href="https://www.adviceguide.org.uk"
												target="_blank"
												rel="noopener noreferrer"
											>
												www.adviceguide.org.uk
											</ALegal>{' '}
											or call 03454 04 05 06.
										</TextLegal>
										<TextLegal>
											The Consumer Rights Act 2015 says our Service must be as described, fit for
											purpose and of satisfactory quality and must be carried out with reasonable
											care and skill. If the digital content provided as part of the Service is
											faulty, you're entitled to a repair or a replacement. If we can’t replace
											it, you can get some or all of your money back (if you paid a fee for the
											Service).
										</TextLegal>
									</OI>
								</OL>
							</OI>
							<OI>
								Price and payment
								<OL>
									<OI>
										This clause only applies where your chosen age verification method involves
										payment of a fee.
									</OI>
									<OI>
										<InlineText bold>Where to find the price for the Service. </InlineText>The price
										of the Service (if applicable) (which includes VAT) will be the price indicated
										on the order submission page when you submitted your order and accepted these
										terms on our website. We take all reasonable care to ensure that the price of
										the Service advised to you is correct. However please see clause 10.3 for what
										happens if we discover an error in the price of the Service you order.
									</OI>
									<OI>
										<InlineText bold>What happens if there is a pricing error. </InlineText>If we
										accept and process your order where a pricing error is obvious and unmistakeable
										and could reasonably have been recognised by you as a mispricing, we may end the
										Agreement, refund you any sums you have paid and void any AgePass provided to
										you.
									</OI>
									<OI>
										<InlineText bold>When you must pay and how you must pay. </InlineText>We accept
										payment via the method listed on our website during the order process.
									</OI>
									You must pay for the Service and we must receive confirmation of such payment before
									we will perform the age verification service or display the AgePass access key to
									you in the browser session or in-App.
								</OL>
							</OI>
							<OI>
								Limitations on and exclusions to our and your legal responsibilities
								<OL>
									<OI>
										<InlineText bold>
											We do not exclude or limit in any way our liability to you where it would be
											unlawful to do so.{' '}
										</InlineText>This means liability for death or personal injury caused by our
										negligence or the negligence of our employees, agents or subcontractors; for
										fraud or fraudulent misrepresentation; for breach of your legal rights in
										relation to the Service, including the right to receive Service which are: as
										described and match information we provided to you and any sample or model seen
										or examined by you; of satisfactory quality; fit for any particular purpose made
										known to us; supplied with reasonable skill and care; and any other liability
										which cannot be limited or excluded by applicable law.
									</OI>
									<OI>
										<InlineText bold>Your and our liability is limited. </InlineText>Subject to the
										other terms of this clause 11, our total liability to you and your aggregate
										liability to us, whether in contract, tort (including negligence), breach of
										statutory duty, or otherwise, arising under or in connection with this Agreement
										shall be limited to the amount paid or payable by you to us under this
										Agreement.
									</OI>
									<OI>
										<InlineText bold>We are not liable for business losses. </InlineText>We only
										supply the Service for domestic and private use. We will have no liability to
										you for any loss of profit, loss of business, business interruption, loss of
										business opportunity, loss of anticipated savings, or loss of or damage to
										goodwill.
									</OI>
									<OI>
										<InlineText bold>
											We are not liable for indirect or consequential losses (that is, losses
											which do not flow naturally, in the usual course of things, from a breach of
											this Agreement) or loss of use or corruption of hardware, software, data or
											information.{' '}
										</InlineText>You understand that we cannot and do not guarantee or warrant that
										files accessed through the internet or mobile carriers’ internet will be free of
										viruses, malware, worms, or other code that may manifest contaminating or
										destructive properties. We do not assume any responsibility or risk for your use
										of the internet or the marketplace where you obtain AgePass/the App or otherwise
										use the Service.
									</OI>
									<OI>
										<InlineText bold>We and you must mitigate any losses suffered. </InlineText>We
										and you shall you use reasonable endeavours to mitigate any loss or damage
										suffered or arising out of or in connection with this Agreement.
									</OI>
									<OI>
										<InlineText bold>
											We are not responsible for the content of any third party website.{' '}
										</InlineText>While AgePass allows access to certain websites containing
										age-restricted products or services by age-verified users, we are not
										responsible for the content of any third party website, the actions of any third
										party website operator, or the consequences of your interaction with any third
										party website.
									</OI>
								</OL>
							</OI>
							<OI>Your personal information</OI>
							<TextLegal>
								To the extent we collect any of your personal information, we will only use it as set
								out in our privacy policy{' '}
								<LinkLegal
									to="/privacy-policy"
								>
									www.agepass.com/privacy-policy
								</LinkLegal>
								.
							</TextLegal>
							<OI>
								Other important terms
								<OL>
									<OI>
										<InlineText bold>We may transfer this agreement to someone else. </InlineText>We
										may transfer our rights and obligations under these terms to another
										organisation.
									</OI>
									<OI>
										<InlineText bold>
											You cannot transfer your rights under this Agreement to someone else.{' '}
										</InlineText>You may only transfer your rights or your obligations under these
										terms to another person with our written consent.
									</OI>
									<OI>
										<InlineText bold>
											Nobody else has any rights under this contract (except someone you pass your
											guarantee on to).{' '}
										</InlineText>This Agreement is between you and us. No other person shall have
										any rights to enforce any of its terms.
									</OI>
									<OI>
										<InlineText bold>
											If a court finds part of this contract illegal, the rest will continue in
											force.{' '}
										</InlineText>{' '}
										Each of the paragraphs of these terms operates separately. If any court or
										relevant authority decides that any of them are unlawful, the remaining
										paragraphs will remain in full force and effect.
									</OI>
									<OI>
										<InlineText bold>
											Even if we delay in enforcing this contract, we can still enforce it later.{' '}
										</InlineText>If we do not insist immediately that you do anything you are
										required to do under these terms, or if we delay in taking steps against you in
										respect of your breaking this contract, that will not mean that you do not have
										to do those things or prevent us taking steps against you at a later date.
									</OI>
									<OI>
										<InlineText bold>
											Which laws apply to this contract and where you may bring legal proceedings.{' '}
										</InlineText>Without prejudice to your statutory rights, these terms are
										governed by the law of the state of Florida, USA and the jurisdiction of the
										Florida courts.
									</OI>
								</OL>
							</OI>
						</OL>
						<Space height="120" />
					</Card>
				</Background>
			</Section>
		</Content>
	);
};

export default withTheme(TermsConditions);

const InlineText = styled(TextLegal)`
    display: inline;
    font-style: ${({ italic }) => (italic ? 'italic' : 'inherit')};
    color: ${({ theme, color = theme.legalColor }) => color};
    &.red {
        color: #f00;
    }
`;

const LinkLegal = styled(GatsbyLink)`
	font-weight: 800;
	color: ${({ theme }) => theme.legalColor};
`;

const spacing = css`
    padding: 52px 50px 40px 50px;
    
		${media.laptop`
        padding: 36px 42px 20px 42px;
    `}

    ${media.phone`
        padding: 36px 42px 20px 42px;
    `}
`;