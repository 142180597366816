import React from 'react';
import { Page } from '../components';
import TermsConditions from '../sections/TermsConditions'


const TermsConditionsPage = () => (
	<Page>
		<TermsConditions />
	</Page>
);

export default TermsConditionsPage;
